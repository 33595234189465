/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Badge,
  Form, ListGroup,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { controlla } from '../../utils/verificaPermessi';
import { client } from '../../utils/matrix';
import { getItem, removeItem, setItem } from '../../utils/storage';
import compartiService from '../../services/compartiService';
import { Context } from '../../utils/Context';
import history from '../../utils/history';

function Sidebar() {
  const [context, setContext] = useContext(Context);
  const { amministrazione, idComparto, idIdentita } = JSON.parse(getItem('identita'));
  const { pathname } = useLocation();

  const [notification, setNotification] = useState(0);
  const [comparti, setComparti] = useState([]);
  const [compartoSelected, setCompartoSelected] = useState({});

  // Function collegata al servizio per ottenere la lista dei comparti
  async function getComparti() {
    try {
      const response = await compartiService.getAllComparti();
      setComparti(response);
      setItem('comparti', JSON.stringify(response));
      if (idComparto) {
        setItem('compartoSelected', JSON.stringify(response.filter((el) => el.sequ_k_comparto === idComparto)[0]));
        setCompartoSelected(idComparto);
      } else {
        setItem('compartoSelected', JSON.stringify(response.filter((el) => el.sequ_k_comparto === 1)[0]));
        setCompartoSelected(1);
      }
    } catch (err) {
      setContext(() => ({
        ...context, open: true, testoErrore: err?.data?.message || 'Errore', statusCode: err?.status,
      }));
    }
  }

  // useEffect
  useEffect(() => {
    client?.on(
      'event',
      () => {
        // let unreadMessage = 0;
        let unreadRoom = 0;
        client.getRooms().forEach((el) => {
          if (el.name !== 'Notifiche' && el.notificationCounts.total) {
            // unreadMessage += el.notificationCounts.total;
            unreadRoom += 1;
          }
        });
        setNotification(unreadRoom);
      },
    );
    if (controlla('Z0')) getComparti();
  }, []);

  // function attivata sull'onChange della select dei comparti
  const onSelectComparto = (comparto) => {
    setItem('compartoSelected', JSON.stringify(comparto));
    setCompartoSelected(comparto);
    history.push(pathname);
  };

  return (
    <nav className="border-end bg-white" id="sidebar-wrapper" role="navigation" aria-label="Menu laterale sinistro">
      <div
        className="sidebar-heading border-bottom ms-1 fw-bold d-flex align-items-center"
        style={{
          verticalAlign: 'middle',
          overflow: 'hidden',
          whiteSpace: 'normal',
          textOverflow: 'clip',
          wordWrap: 'break-word',
          overflowY: 'hidden',
          textAlign: 'center',
        }}
      >
        {amministrazione}
      </div>
      <ListGroup>
        {controlla('Z0') && (
        <ListGroup.Item>
          <Form.Label>
            Comparto
          </Form.Label>
          <Select
            id="comparto"
            name="comparto"
            options={comparti}
            onChange={(selectedOption) => onSelectComparto(selectedOption)}
            placeholder="Scegli comparto"
            isDisabled={idComparto}
            getOptionLabel={(option) => option.desc_comparto}
            getOptionValue={(option) => option.sequ_k_comparto}
            value={comparti?.filter(
              (el) => el.sequ_k_comparto === compartoSelected,
            )?.[0]}
          />
        </ListGroup.Item>
        )}
        <ListGroup.Item>
          <Link to="/area-riservata">
            <FontAwesomeIcon aria-hidden="true" icon="home" style={{ marginRight: '4px' }} size="lg" />
            {' '}
            Home
          </Link>
        </ListGroup.Item>
        {(controlla('Z1') && (controlla('X46') || controlla('X47') || controlla('X48'))) && (
          <ListGroup.Item>
            <Link to="/area-riservata/amministrazione">
              <FontAwesomeIcon aria-hidden="true" icon="users" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Amministrazione
            </Link>
          </ListGroup.Item>
        )}
        {controlla('Z2') && (
          <ListGroup.Item>
            <Link to="/area-riservata/richiesta-identita">
              <FontAwesomeIcon aria-hidden="true" icon="id-badge" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Richiesta accreditamento
            </Link>
          </ListGroup.Item>
        )}

        {controlla('Z14') && (
          <>
            {(controlla('Z3') || controlla('Z4') || controlla('Z5') || controlla('Z6') || controlla('Z7') || controlla('Z11')) && (
            <Accordion className="border-end">
              <Accordion.Item eventKey="0" className="border-end">
                <Accordion.Header>
                  Anagrafiche
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ListGroup>
                    {controlla('Z3') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/area-contrattuale">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-landmark" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Aree Contrattuali CCNL
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z4')
                  && (JSON.parse(getItem('compartoSelected'))?.sequ_k_comparto === 1 || JSON.parse(getItem('compartoSelected'))?.sequ_k_comparto === 2)
                  && (
                  <ListGroup.Item onClick={() => removeItem('filtri')}>
                    <Link to="/area-riservata/dimensioni-professionali">
                      <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-city" style={{ marginRight: '4px' }} size="lg" />
                      {' '}
                      Dimensioni Professionali
                    </Link>
                  </ListGroup.Item>
                  )}
                    {controlla('Z5')
               && (
               <ListGroup.Item onClick={() => removeItem('filtri')}>
                 <Link to="/area-riservata/famiglie-professionali">
                   <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-user-tie" style={{ marginRight: '4px' }} size="lg" />
                   {' '}
                   Famiglie Professionali
                 </Link>
               </ListGroup.Item>
               )}
                    {controlla('Z7') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/ambiti-ruolo">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-circle-nodes" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Ambiti di Ruolo
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z6') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/aree-competenze">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-file-contract" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Aree delle Competenze
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z11') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/grading">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-person-dots-from-line" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Grading
                      </Link>
                    </ListGroup.Item>
                    )}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            )}
            <Accordion className="border-end">
              <Accordion.Item eventKey="0" className="border-end">
                <Accordion.Header>
                  Library delle Competenze
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ListGroup>
                    {controlla('Z8') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/competenze/CTP">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-handshake" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Competenze Tecnico Professionali
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z9') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/competenze/CC">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-handshake" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Competenze Comportamentali
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z10') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/competenze/CTS">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-handshake" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Competenze Tecnico Specialistiche
                      </Link>
                    </ListGroup.Item>
                    )}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="border-end">
              <Accordion.Item eventKey="0" className="border-end">
                <Accordion.Header>
                  Library dei Profili Professionali
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ListGroup>
                    {(controlla('Z12')) && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/profili-professionali">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-chalkboard-user" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Profili Professionali
                      </Link>
                    </ListGroup.Item>
                    )}
                    {controlla('Z13') && (
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/profili-ruolo">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-id-card-clip" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Profili di Ruolo
                      </Link>
                    </ListGroup.Item>
                    )}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
        {(controlla('Z18') || controlla('Z31')) && (
        <Accordion className="border-end">
          <Accordion.Item eventKey="1" className="border-end">
            <Accordion.Header>
              Programmazione del Fabbisogno
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <ListGroup>
                {controlla('Z15') && (
                  <>
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/assessment">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-arrows-spin" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Cicli di Assessment
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item onClick={() => removeItem('filtri')}>
                      <Link to="/area-riservata/analisi-assessment">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-file-contract" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Analisi Esiti
                      </Link>
                    </ListGroup.Item>
                  </>
                )}
                {controlla('Z31') && (
                <ListGroup.Item onClick={() => removeItem('filtri')}>
                  <Link to="/area-riservata/demo-bpm">
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-file-circle-plus" style={{ marginRight: '4px' }} size="lg" />
                    {' '}
                    {idIdentita === 10 ? 'Richieste di Fabbisogno' : 'Piani di Fabbisogno'}
                  </Link>
                </ListGroup.Item>
                )}
                {controlla('Z16') && (
                <ListGroup.Item onClick={() => removeItem('filtri')}>
                  <Link to="/area-riservata/auto-assessment">
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-person-arrow-up-from-line" style={{ marginRight: '4px' }} size="lg" />
                    {' '}
                    Auto Valutazione
                  </Link>
                </ListGroup.Item>
                )}
                {controlla('Z17') && (
                <ListGroup.Item onClick={() => removeItem('filtri')}>
                  <Link to="/area-riservata/etero-assessment">
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-person-arrow-down-to-line" style={{ marginRight: '4px' }} size="lg" />
                    {' '}
                    Etero Valutazione
                  </Link>
                </ListGroup.Item>
                )}
                {controlla('Z32') && (
                <ListGroup.Item onClick={() => removeItem('filtri')}>
                  <Link to="/area-riservata/scheda-competenze">
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-clipboard-user" style={{ marginRight: '4px' }} size="lg" />
                    {' '}
                    Scheda Competenze
                  </Link>
                </ListGroup.Item>
                )}
                {controlla('Z33') && (
                <ListGroup.Item onClick={() => removeItem('filtri')}>
                  <Link to="/area-riservata/lista-utenti-schede">
                    <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-users-rectangle" style={{ marginRight: '4px' }} size="lg" />
                    {' '}
                    Schede Competenze
                  </Link>
                </ListGroup.Item>
                )}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        )}
        {controlla('Z28') && (
          <Accordion className="border-end">
            <Accordion.Item eventKey="1" className="border-end">
              <Accordion.Header>
                Reporting
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <ListGroup>
                  <ListGroup.Item>
                    <Link to="/area-riservata/statistiche-sistema-professionale">
                      <FontAwesomeIcon aria-hidden="true" icon="chart-column" size="lg" />
                      {' '}
                      Sistema Professionale
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link to="/area-riservata/statistiche-matrice-profili-professionali">
                      <FontAwesomeIcon aria-hidden="true" icon="table" size="lg" />
                      {' '}
                      Sintesi dei Profili Professionali e di Ruolo
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link to="/area-riservata/statistiche-assessment">
                      <FontAwesomeIcon aria-hidden="true" icon="chart-simple" size="lg" />
                      {' '}
                      Assessment Gap Analysis
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {(controlla('Z19')
          || controlla('Z22')
          || controlla('Z24')
          || controlla('Z20')
          || controlla('Z23')
          || controlla('Z25')
          || controlla('Z26')
          || controlla('Z27')
          || (idIdentita !== 1 && idIdentita !== 6)
        ) && (
          <Accordion className="border-end">
            <Accordion.Item eventKey="0" className="border-end">
              <Accordion.Header>
                Utility
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <ListGroup>
                  {controlla('Z19') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/gestione-news">
                      <FontAwesomeIcon aria-hidden="true" icon="file-shield" size="lg" />
                      {' '}
                      Gestione news
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z22') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/gestione-news-esterne">
                      <FontAwesomeIcon aria-hidden="true" icon="newspaper" size="lg" />
                      {' '}
                      Gestione news esterne
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z24') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/news">
                      <FontAwesomeIcon aria-hidden="true" icon="box-archive" size="lg" />
                      {' '}
                      News
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z20') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/gestione-faq">
                      <FontAwesomeIcon aria-hidden="true" icon="person-circle-question" size="lg" />
                      {' '}
                      Gestione FAQ
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z23') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/gestione-faq-esterne">
                      <FontAwesomeIcon aria-hidden="true" icon="question-circle" size="lg" />
                      {' '}
                      Gestione FAQ esterne
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z25') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/faq">
                      <FontAwesomeIcon aria-hidden="true" icon="clipboard-question" size="lg" />
                      {' '}
                      FAQ
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z26') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/template">
                      <FontAwesomeIcon aria-hidden="true" icon="file-invoice" size="lg" />
                      {' '}
                      Gestione questionari
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z27') && (
                  <ListGroup.Item>
                    <Link to="/area-riservata/questionari">
                      <FontAwesomeIcon aria-hidden="true" icon="file-signature" size="lg" />
                      {' '}
                      Questionari
                    </Link>
                  </ListGroup.Item>
                  )}
                  {controlla('Z34') && (
                    <ListGroup.Item>
                      <Link to="/area-riservata/forum">
                        <FontAwesomeIcon aria-hidden="true" icon="comments" size="lg" />
                        {' '}
                        Forum
                      </Link>
                    </ListGroup.Item>
                  )}
                  {idIdentita === 6 && (
                  <ListGroup.Item>
                    <a href="/content/manuale-dipendente.pdf" target="_blank">
                      <FontAwesomeIcon aria-hidden="true" icon="book" size="lg" />
                      {' '}
                      Manuale Dipendente
                    </a>
                  </ListGroup.Item>
                  )}
                  {(idIdentita !== 1 && idIdentita !== 6) && (
                  <ListGroup.Item>
                    <a href="/content/manuale-toolkit.pdf" target="_blank">
                      <FontAwesomeIcon aria-hidden="true" icon="book" size="lg" />
                      {' '}
                      Manuale Utente
                    </a>
                  </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {(controlla('Z29') || controlla('Z30')) && (
          <Accordion className="border-end">
            <Accordion.Item eventKey="1" className="border-end">
              <Accordion.Header>
                Demo Assessment
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <ListGroup>
                  {controlla('Z29') && (
                    <ListGroup.Item>
                      <Link to="/area-riservata/demo-assessment/ciclo-assessment">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-arrows-spin" size="lg" />
                        {' '}
                        Cicli Assessment
                      </Link>
                    </ListGroup.Item>
                  )}
                  {controlla('Z30') && (
                    <ListGroup.Item>
                      <Link to="/area-riservata/demo-assessment/elenco-assessment">
                        <FontAwesomeIcon aria-hidden="true" icon="fa-solid fa-person-arrow-down-to-line" style={{ marginRight: '4px' }} size="lg" />
                        {' '}
                        Assessment
                      </Link>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {client && (controlla('Z21') || controlla('Z29')) && (
          <ListGroup.Item>
            <Link to="/area-riservata/chat">
              <FontAwesomeIcon aria-hidden="true" icon="comment" style={{ marginRight: '4px' }} size="lg" />
              {' '}
              Chat
              {' '}
              {notification && !history.location.pathname.includes('chat')
                ? <Badge bg="grey">{notification}</Badge>
                : null}
            </Link>
          </ListGroup.Item>
        )}
      </ListGroup>
    </nav>
  );
}

export default Sidebar;
